import { useContext, createContext, useState } from 'react'
import { ModalHunter } from '../../components/ModalHunter/ModalHunter'

const ModalHunterContext = createContext({})

function ModalHunterProvider({ children }) {
  const [openModalHunter, setOpenModalHunter] = useState(false)
  const [dataModalHunter, setDataModalHunter] = useState(null)

  const handleCloseModalHunter = () => {
    setDataModalHunter(null)
    setOpenModalHunter(false)
  }

  const handleOpenModalHunter = async () => {
    setOpenModalHunter(true)
  }

  return (
    <ModalHunterContext.Provider
      value={{
        openModalHunter,
        handleOpenModalHunter,
        handleCloseModalHunter,
        dataModalHunter,
        setDataModalHunter
      }}
    >
      <ModalHunter />
      {children}
    </ModalHunterContext.Provider>
  )
}

function useModalHunter() {
  return useContext(ModalHunterContext)
}

export { ModalHunterProvider, useModalHunter }
