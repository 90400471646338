import { Badge, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { AiFillThunderbolt } from 'react-icons/ai'
import { useModalHunter } from '../../utility/hooks/useModalHunter'
export const ModalHunter = () => {
  const { openModalHunter, handleCloseModalHunter, dataModalHunter } =
    useModalHunter()

  return (
    <Modal centered isOpen={openModalHunter} toggle={handleCloseModalHunter}>
      <ModalHeader className="w-100 justify-content-center">
        Roleta Realizada
      </ModalHeader>
      <ModalBody>
        <section className="d-flex align-items-center pb-1 justify-content-center">
          <AiFillThunderbolt />
          <span className="font-weight-bolder ml-1">
            Detalhes da roleta realizada
          </span>
        </section>
        <aside>
          <span className="d-flex justify-content-center">
            <small>
              Veja aqui as informações sobre o evento que foi gerado
            </small>
          </span>
        </aside>

        <div className="border-bottom my-1"></div>

        <div
          style={{ gap: '8px' }}
          className="d-flex font-weight-bolder justify-content-center align-items-center flex-wrap"
        >
          <Badge pill>
            Assessor roletado: {dataModalHunter?.roulette?.accessor}
          </Badge>
          <Badge pill>Data: {dataModalHunter?.roulette?.date}</Badge>
          <Badge pill>Horário: {dataModalHunter?.roulette?.time}</Badge>
          <Badge pill>Fila roletada: {dataModalHunter?.roulette?.fila}</Badge>
          <Badge pill>Squad: {dataModalHunter?.roulette?.equipe}</Badge>
        </div>
      </ModalBody>
    </Modal>
  )
}
